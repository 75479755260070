
import PropTypes from "prop-types";
import React from "react";

const RotateCw = ({ color, size, ...props }) => (
    <svg viewBox="0 0 22 22" width={size} height={size} {...props}>
        
	<path d="M5.648 2.977A9.167 9.167 0 0 1 16.55 4.503l2.699 2.537V3.658a.917.917 0 0 1 1.833 0v5.5c0 .193-.061.38-.173.537a.924.924 0 0 1-.744.38h-5.5a.917.917 0 1 1 0-1.833h3.187L15.285 5.83l-.021-.02A7.333 7.333 0 1 0 17 13.438a.917.917 0 0 1 1.729.61 9.166 9.166 0 1 1-13.08-11.07Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default RotateCw;

RotateCw.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
