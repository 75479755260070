import React, { useState, useEffect } from "react";
import classes from "./AppLayoutHeaderMobile.module.css";
import logoImage from "assets/logo.png";
import Icon from "Components/Icon";
import Drawer from "Components/Drawer";
import IconButton from "Components/IconButton";
import useReportsConnections from "Hooks/useReportsConnections";
import useProvidersConnections from "Hooks/useProvidersConnections";
import useTransactionsCount from "Hooks/useTransactionsCount";
import useIntegrations from "Hooks/useIntegrations";
import useDashboards from "Hooks/useDashboards";
import { ROUTES, HELP_ROUTES } from "Utils/constants";
import { Link, useLocation } from "react-router-dom";
import Badge from "Components/Badge";
import Skeleton from "Components/Skeleton";
import useCategories from "Hooks/useCategories";
import { useUser } from "Components/UserProvider/useUser";
import HeaderOnboarding from "Components/HeaderOnboarding";
import clsx from "clsx";
import UpgradeButton from "Components/UpgradeButton";
import useInvoicesCount from "Hooks/useInvoicesCount";
import ChangeWorkspaceButton from "./ChangeWorkspaceButton";
import useForecasts from "Hooks/useForecasts";
import Container from "Components/Container";
import { useTranslation } from "react-i18next";
import useInfiniteAutomations from "Hooks/useInfiniteAutomations";
import useInfiniteAlerts from "Hooks/useInfiniteAlerts";
import emojiImage from "assets/emoji.png";
import useCategoriesSuggestions from "Hooks/useCategoriesSuggestions";
import CategorizerModal from "Sections/CategorizerModal";
import useInfiniteContacts from "Hooks/useInfiniteContacts";

export default function AppLayoutHeaderMobile() {
    const [openMobileMenu, setOpenMobileMenu] = useState(false);
    const { logout } = useUser();
    const { pathname } = useLocation();

    const { reportsConnections, isLoading: isReportsConnectionsLoading } = useReportsConnections();
    const { data: automations, isLoading: isAutomationsLoading } = useInfiniteAutomations();
    const { data: alerts, isLoading: isAlertsLoading } = useInfiniteAlerts();
    const { transactionsCount, isLoading: isTransactionsLoading } = useTransactionsCount();
    const { dashboards, isLoading: isDashboardsLoading } = useDashboards();
    const { validProvidersConnections: providersConnections, isLoading: isProvidersConnectionsLoading } = useProvidersConnections();
    const { integrations, isLoading: isIntegrationsLoading } = useIntegrations();
    const { categoriesCount, isLoading: isCategoriesLoading } = useCategories();
    const { invoicesCount, isLoading: isInvoiceLoading } = useInvoicesCount();
    const { forecasts, isLoading: isForecastsLoading } = useForecasts();
    const { count: categoriesSuggestionsCount } = useCategoriesSuggestions();
    const { data: contacts, isLoading: isContactsLoading } = useInfiniteContacts();
    const [openCategorizerModal, setOpenCategorizerModal] = useState(false);

    const automationsCount = Array.isArray(automations?.pages) ? automations.pages[0]?.count : 0;
    const alertsCount = Array.isArray(alerts?.pages) ? alerts.pages[0]?.count : 0;
    const contactsCount = Array.isArray(contacts?.pages) ? contacts.pages[0]?.count : 0;

    const { t, i18n } = useTranslation("common");
    const helpRoute = HELP_ROUTES[i18n.language] || HELP_ROUTES.EN;

    useEffect(() => {
        setOpenMobileMenu(false);
    }, [pathname]);

    return (
        <>
            <Container>
                <div className={classes.navbar}>
                    <Link to={ROUTES.dashboards}>
                        <img src={logoImage} alt="Logo de Banktrack" height={28} width={136} />
                    </Link>
                    <div className="flex-grow-1" />

                    <div className="d-flex gap-3 align-items-center">
                        <HeaderOnboarding />

                        <UpgradeButton narrow />

                        <IconButton onClick={() => setOpenMobileMenu(true)} className="flex-shrink-0" >
                            <Icon name="menu" gradient="primary" size={24} />
                        </IconButton>
                    </div>
                </div>
            </Container>

            <Drawer
                size="sm"
                open={openMobileMenu}
                onClose={() => setOpenMobileMenu(false)}
                anchor="left"
            >
                <div className={classes.drawerContainer}>
                    <nav className={classes.mobileNav}>
                        <ul>
                            <li>
                                <Link className={clsx(classes.navItem, { [classes.current]: pathname.startsWith(ROUTES.dashboards) })} to={ROUTES.dashboards}>
                                    <Icon name="nav-dashboard" color="currentColor" size={24} className="flex-shrink-0" />
                                    <span className="flex-grow-1">
                                        {t("dashboards")}
                                    </span>
                                    {isDashboardsLoading && (
                                        <Skeleton variant="rounded" width={22} height={22} sx={{ borderRadius: 999 }} />
                                    )}

                                    {!isDashboardsLoading && (
                                        <Badge className={classes.badge}>
                                            {dashboards?.length ?? 0}
                                        </Badge>
                                    )}
                                </Link>
                            </li>
                            <li>
                                <Link className={clsx(classes.navItem, { [classes.current]: pathname.startsWith(ROUTES.forecasts) })} to={ROUTES.forecasts}>
                                    <Icon name="nav-forecast" color="currentColor" size={24} className="flex-shrink-0" />
                                    <span className="flex-grow-1">
                                        {t("forecasts")}
                                    </span>
                                    {isForecastsLoading ? (
                                        <Skeleton variant="rounded" width={18} height={18} sx={{ borderRadius: 999 }} />
                                    ) : (
                                        <Badge className={classes.badge} size="small">{forecasts?.length ?? 0}</Badge>
                                    )}
                                </Link>
                            </li>

                            <li>
                                <Link className={clsx(classes.navItem, { [classes.current]: pathname === ROUTES.search })} to={ROUTES.search}>
                                    <Icon name="nav-transactions" color="currentColor" size={24} className="flex-shrink-0" />
                                    <span className="flex-grow-1">
                                        {t("transactions")}
                                    </span>
                                    {isTransactionsLoading && (
                                        <Skeleton variant="rounded" width={46} height={22} sx={{ borderRadius: 999 }} />
                                    )}

                                    {!isTransactionsLoading && (
                                        <Badge className={classes.badge}>
                                            {transactionsCount ?? 0}
                                        </Badge>
                                    )}
                                </Link>
                            </li>
                            <li>
                                <Link className={clsx(classes.navItem, { [classes.current]: pathname.startsWith(ROUTES.invoices) })} to={ROUTES.invoices}>
                                    <Icon name="nav-invoices" color="currentColor" size={24} className="flex-shrink-0" />
                                    <span className="flex-grow-1">
                                        {t("invoices")}
                                    </span>
                                    {isInvoiceLoading ? (
                                        <Skeleton variant="rounded" width={18} height={18} sx={{ borderRadius: 999 }} />
                                    ) : (
                                        <Badge className={classes.badge} size="small">{invoicesCount}</Badge>
                                    )}
                                </Link>
                            </li>
                            <li>
                                <Link className={clsx(classes.navItem, { [classes.current]: pathname === ROUTES.reports })} to={ROUTES.reports}>
                                    <Icon name="nav-reports" color="currentColor" size={24} className="flex-shrink-0" />
                                    <span className="flex-grow-1">
                                        {t("reports")}
                                    </span>
                                    {isReportsConnectionsLoading && (
                                        <Skeleton variant="rounded" width={22} height={22} sx={{ borderRadius: 999 }} />
                                    )}

                                    {!isReportsConnectionsLoading && (
                                        <Badge className={classes.badge}>
                                            {reportsConnections?.length ?? 0}
                                        </Badge>
                                    )}
                                </Link>
                            </li>
                            <li>
                                <Link className={clsx(classes.navItem, { [classes.current]: pathname === ROUTES.alerts })} to={ROUTES.alerts}>
                                    <Icon name="nav-alerts" color="currentColor" size={24} className="flex-shrink-0" />
                                    <span className="flex-grow-1">
                                        {t("alerts")}
                                    </span>
                                    {isAlertsLoading && (
                                        <Skeleton variant="rounded" width={22} height={22} sx={{ borderRadius: 999 }} />
                                    )}

                                    {!isAlertsLoading && (
                                        <Badge className={classes.badge}>
                                            {alertsCount}
                                        </Badge>
                                    )}
                                </Link>
                            </li>
                            <li className={classes.divider} />
                            <li>
                                <Link className={clsx(classes.navItem, { [classes.current]: pathname === ROUTES.providers })} to={ROUTES.providers}>
                                    <Icon name="nav-providers" color="currentColor" size={24} className="flex-shrink-0" />
                                    <span className="flex-grow-1">
                                        {t("banks")}
                                    </span>
                                    {isProvidersConnectionsLoading ? (
                                        <Skeleton variant="rounded" width={18} height={18} sx={{ borderRadius: 999 }} />
                                    ) : (
                                        <Badge className={classes.badge}>{providersConnections?.length ?? 0}</Badge>
                                    )}
                                </Link>
                            </li>
                            <li>
                                <Link className={clsx(classes.navItem, { [classes.current]: pathname === ROUTES.integrations })} to={ROUTES.integrations}>
                                    <Icon name="nav-channels" color="currentColor" size={24} className="flex-shrink-0" />
                                    <span className="flex-grow-1">
                                        {t("integrations")}
                                    </span>
                                    {isIntegrationsLoading ? (
                                        <Skeleton variant="rounded" width={18} height={18} sx={{ borderRadius: 999 }} />
                                    ) : (
                                        <Badge className={classes.badge}>{integrations?.length ?? 0}</Badge>
                                    )}
                                </Link>
                            </li>
                            <li>
                                <Link className={clsx(classes.navItem, { [classes.current]: pathname === ROUTES.contacts })} to={ROUTES.contacts}>
                                    <Icon name="nav-contacts" color="currentColor" size={24} className="flex-shrink-0" />

                                    <span className="flex-grow-1">
                                        {t("contacts")}
                                    </span>
                                    {isContactsLoading ? (
                                        <Skeleton variant="rounded" width={18} height={18} sx={{ borderRadius: 999 }} />
                                    ) : (
                                        <Badge className={classes.badge} size="small">{contactsCount}</Badge>
                                    )}
                                </Link>
                            </li>
                            <li>
                                <Link className={clsx(classes.navItem, { [classes.current]: pathname === ROUTES.categories })} to={ROUTES.categories}>
                                    <Icon name="nav-categories" color="currentColor" size={24} className="flex-shrink-0" />
                                    <span className="flex-grow-1">
                                        {t("categories")}
                                    </span>
                                    {isCategoriesLoading ? (
                                        <Skeleton variant="rounded" width={18} height={18} sx={{ borderRadius: 999 }} />
                                    ) : (
                                        <Badge className={classes.badge}>{categoriesCount}</Badge>
                                    )}
                                </Link>
                            </li>
                            <li>
                                <Link className={clsx(classes.navItem, { [classes.current]: pathname === ROUTES.automations })} to={ROUTES.automations}>
                                    <Icon name="nav-automations" color="currentColor" size={24} className="flex-shrink-0" />
                                    <span className="flex-grow-1">
                                        {t("automations")}
                                    </span>
                                    {isAutomationsLoading && (
                                        <Skeleton variant="rounded" width={22} height={22} sx={{ borderRadius: 999 }} />
                                    )}

                                    {!isAutomationsLoading && (
                                        <Badge className={classes.badge}>
                                            {automationsCount}
                                        </Badge>
                                    )}
                                </Link>
                            </li>
                            {categoriesSuggestionsCount > 0 && (
                                <li>
                                    <button type="button" className={clsx(classes.navItem)} onClick={() => {
                                        setOpenCategorizerModal(true);
                                        setOpenMobileMenu(false);
                                    }}>
                                        <img src={emojiImage} alt="Emoji" width={24} height={24} />

                                        <span className="flex-grow-1">
                                            {t("uncategorized-nav")}
                                        </span>
                                        <Badge className={classes.badge} size="small">{categoriesSuggestionsCount}</Badge>
                                    </button>
                                </li>
                            )}
                            <li>
                                <Link className={classes.navItem} to={helpRoute} target="_blank" rel="noopener noreferrer">
                                    <Icon name="nav-docs" color="currentColor" size={24} className="flex-shrink-0" />

                                    <span className="flex-grow-1">
                                        {t("help")}
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </nav>

                    <div className="flex-grow-1" />

                    <div className={classes.secondaryNav}>
                        <div className={classes.dividerSmall} />

                        <ChangeWorkspaceButton className="px-0" />

                        <div className={clsx(classes.dividerSmall, "mb-2")} />

                        <Link className={classes.secondaryNavItem} to={ROUTES.profile}>
                            {t("edit-profile")}
                        </Link>
                        <Link className={classes.secondaryNavItem} to={ROUTES.affiliates}>
                            {t("invite-and-earn")}
                        </Link>
                        <Link className={classes.secondaryNavItem} to={ROUTES.settings}>
                            {t("settings")}
                        </Link>
                        <a className={classes.secondaryNavItem} href="#" onClick={e => { e.preventDefault(); logout() }}>
                            {t("logout")}
                        </a>
                    </div>
                </div>
            </Drawer>

            <CategorizerModal
                open={openCategorizerModal}
                onClose={() => setOpenCategorizerModal(false)}
            />
        </>
    )
}