import React from 'react';
import PropTypes from 'prop-types';
import ProviderConnectionConnect from "Sections/ProviderConnectionConnect";

export default function ProviderConnectionConnectDialogContent(props) {
    const { providerConnection, onConnect, onCancel } = props;

    return (
        <ProviderConnectionConnect
            onConnect={onConnect}
            onError={onCancel}
            onCancel={onCancel}
            connector={providerConnection?.connector}
            providerConnectionId={providerConnection?.id}
        />
    );
}

ProviderConnectionConnectDialogContent.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConnect: PropTypes.func.isRequired,
    providerConnection: PropTypes.object
}