import {
    autoUpdate,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useRole,
    useMergeRefs,
    useListNavigation
} from "@floating-ui/react";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { flip, offset, size, } from "@floating-ui/react-dom";
import PropTypes from "prop-types";
import classes from "./AutocompleteInfinite.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import Spinner from "Components/Spinner";
import InputField from 'Components/InputField';
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import Icon from "Components/Icon";
import { getContact } from "Services/invoices";
import { trackError } from "Utils/errorMonitoring";

const AutocompleteInfinite = forwardRef(function AutocompleteInfinite(props, ref) {
    const {
        label, options, className, value, error,
        disabled, query, onChangeQuery, isFetching, fetchNextPage, hasNextPage,
        onSelect, onAdd
    } = props;

    const sentinelRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const { t } = useTranslation("contacts");
    const [displayValue, setDisplayValue] = useState("");

    const { x, y, refs, strategy, context } = useFloating({
        whileElementsMounted: autoUpdate,
        open: isOpen,
        placement: "bottom-start",
        middleware: [
            flip(),
            size({
                apply({ rects, elements }) {
                    Object.assign(elements.floating.style, {
                        width: `${Math.max(rects.reference.width, 200)}px`
                    });
                }
            }),
            offset(4),
        ],
        onOpenChange: (requestOpen, event) => {
            if (event.type === "keyup" && event.code === "Space") {
                onChangeQuery(`${query} `);
                return;
            }
            setIsOpen(requestOpen);
            if (!requestOpen) {
                onChangeQuery("");
            }
        }
    });

    const listRef = useRef([]);

    const listNavigation = useListNavigation(context, {
        listRef,
        activeIndex,
        onNavigate: setActiveIndex,
        virtual: true,
        focusItemOnOpen: false,
        allowEscape: false
    });

    const inputRef = useMergeRefs([refs.setReference, ref]);

    useEffect(() => {
        if (sentinelRef.current) {
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        if (hasNextPage) {
                            fetchNextPage();
                        }
                    }
                });
            });

            observer.observe(sentinelRef.current);

            return () => {
                observer.disconnect();
            }
        }
    }, [sentinelRef, hasNextPage, isOpen]);

    const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
        listNavigation,
        useClick(context),
        useRole(context, { role: "menu" }),
        useDismiss(context)
    ]);

    const handleFilter = (ev) => {
        onChangeQuery(ev.target.value);
    }

    const inputValue = isOpen ? query : displayValue;

    useEffect(() => {
        if (!value || options.length === 0) {
            setDisplayValue("");
            return;
        }

        const matchedOption = options.find(option => option.id === value);

        if (matchedOption) {
            setDisplayValue(matchedOption.name);
        } else {
            setDisplayValue("");
            getContact(value)
                .then(contact => {
                    if (contact) {
                        setDisplayValue(contact.name);
                    }
                }).catch((error) => {
                    trackError(error);
                });
        }
    }, [value, options]);

    useEffect(() => {
        if (isFetching) {
            setActiveIndex(null);
        }

        if (query.trim() === "") {
            setActiveIndex(null);
        } else {
            const selectedOption = options.length > 0 ? 1 : 0;
            setActiveIndex(selectedOption);
        }
    }, [query, isFetching, options]);

    const hasAddNew = typeof onAdd === "function";

    return (
        <div className={clsx(className, classes.wrapper)}>
            <InputField
                label={label}
                value={inputValue}
                disabled={disabled}
                ref={inputRef}
                error={error}
                inputProps={{
                    autoComplete: "off",
                }}
                {...getReferenceProps({
                    onClick: () => setIsOpen(true),
                    onChange: handleFilter,
                    onKeyDown: (ev) => {
                        if (ev.key === "Enter" && activeIndex !== null) {
                            ev.preventDefault();

                            if (activeIndex === 0) {
                                onAdd?.(query);
                            } else {
                                onSelect(options[activeIndex - 1].id);
                            }
                        }
                    }
                })}
            />

            {isFetching && (
                <div className={classes.loading}>
                    <Spinner size={20} />
                </div>
            )}

            {isOpen && (
                <div
                    className={classes.options}
                    {...getFloatingProps({
                        ref: refs.setFloating,
                        style: {
                            position: strategy,
                            top: y ?? 0,
                            left: x ?? 0,
                            zIndex: 2,
                        }
                    })}>
                    {hasAddNew && (
                        <button
                            type="button"
                            className={clsx(classes.option, classes.addNew, activeIndex === 0 && classes.actived)}
                            tabIndex={activeIndex === 0 ? 0 : -1}
                            ref={(node) => {
                                listRef.current[0] = node;
                            }}
                            {...getItemProps({
                                onClick: () => onAdd(query)
                            })}
                        >
                            <div className="d-flex align-items-center gap-2">
                                <div>
                                    <Icon name="circle-plus" color="currentColor" size={24} />
                                </div>
                                <div className={classes.name}>
                                    {t("add-new")}
                                </div>
                            </div>
                        </button>
                    )}

                    {options.map((option, index) => {
                        const optionIndex = index + 1;

                        return (
                            <button
                                type="button"
                                className={clsx(classes.option, activeIndex === optionIndex && classes.actived)}
                                tabIndex={activeIndex === optionIndex ? 0 : -1}
                                key={option.id}
                                ref={(node) => {
                                    listRef.current[optionIndex] = node;
                                }}
                                {...getItemProps({
                                    onClick: () => {
                                        onSelect(option.id);
                                        setIsOpen(false);
                                    }
                                })}
                            >
                                <div className={classes.name}>
                                    {option.name}
                                </div>
                                <div className={classes.description}>
                                    {option.type === "company" && t("company")}
                                    {option.type === "freelancer" && t("freelancer")}
                                </div>
                            </button>
                        )
                    })}

                    {hasNextPage && (
                        <div className="text-center text-muted small" ref={sentinelRef}>
                            {isFetching ?
                                <Spinner /> :
                                <FontAwesomeIcon icon={faArrowDown} className="cursor-pointer" onClick={fetchNextPage} />
                            }
                        </div>
                    )}
                </div>
            )}
        </div>
    );
});

export default AutocompleteInfinite;

AutocompleteInfinite.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSelect: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    query: PropTypes.string.isRequired,
    onChangeQuery: PropTypes.func.isRequired,
    onAdd: PropTypes.func,
    isFetching: PropTypes.bool,
    fetchNextPage: PropTypes.func,
    hasNextPage: PropTypes.bool,
    error: PropTypes.string,
}
