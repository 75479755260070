
export function normalize(str) {
    return str.trim().toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
}

export function hasMatch(word, query) {
    return normalize(word).includes(normalize(query));
}

export function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}