import { useQuery } from "@tanstack/react-query";
import { getIsLatestAppVersion } from "Services/others";
import { useEffect } from "react";
import { getCookie, setCookie, isCookieEnabled } from "Utils/cookies";
import { trackError } from "Utils/errorMonitoring";
import { isLocalStorageAvailable } from "Utils/storage";
import { COOKIES } from "Utils/constants";

function getBrowserInfo() {
    return {
        userAgent: navigator.userAgent,
        cookieEnabled: navigator.cookieEnabled,
        storageAvailable: isLocalStorageAvailable(),
    };
}

const HOURS_1 = 1000 * 60 * 60 * 1;

export default function useCheckLastAppVersion() {
    const { data: isLastestAppVersion } = useQuery(["is-latest-app-version"], getIsLatestAppVersion, {
        staleTime: 1000 * 60 * 60 * 1, // 1 hour
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        refetchInterval: 1000 * 60 * 60 * 1, // 1 hour
    });

    useEffect(() => {
        if (isLastestAppVersion === false) {
            if (!isCookieEnabled()) {
                console.log("Cookies are not enabled, please update the app manually to the latest version");
                return;
            }

            const hasBeenReloaded = getCookie(COOKIES.LAST_APP_VERSION_RELOADED);

            if (hasBeenReloaded === "true") {
                const browserInfo = getBrowserInfo();

                trackError("App version is not latest and has been reloaded before", browserInfo);
                return;
            }

            setCookie(COOKIES.LAST_APP_VERSION_RELOADED, "true", HOURS_1);
            window.location.reload();
        }
    }, [isLastestAppVersion]);
}