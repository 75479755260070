import axiosInstance from "Utils/axiosInstance";
import { mapDtoToCategory } from "Utils/mappers";

const wait = ms => new Promise((resolve) => setTimeout(resolve, ms));

const mapDtoToFile = (dto) => ({
    ...dto,
    creation_date: new Date(dto.creation_date),
});

const sortFileByCreationDate = (a, b) => {
    if (a.creation_date < b.creation_date) return -1;
    if (a.creation_date > b.creation_date) return 1;
    return 0;
}

export async function deleteTransaction(transactionId) {
    const response = await axiosInstance.delete("/providers/transactions/" + transactionId);

    if (!response?.data) return null;

    return response.data;
}


export async function getTransactionsCount() {
    const response = await axiosInstance.get("/providers/transactions");
    if (!response?.data) return 0;

    return response.data.count;
}

export async function getExportItem(exportId) {
    const response = await axiosInstance.get("/providers/transactions/export/" + exportId);
    if (!response?.data) return null;

    return response.data;
}

const mapDtoToTransaction = (dto) => ({
    ...dto,
    creation_date: new Date(dto.creation_date),
    date_booked: new Date(dto.date_booked),
    date_executed: dto.date_executed ? new Date(dto.date_executed) : null,
    is_premium_blocked: dto.is_premium_blocked || false,
})

export async function getTransactions(searchParams) {
    const paramsNormalized = Object.keys(searchParams).reduce((acc, key) => {
        if (searchParams[key] !== null) {
            acc[key] = searchParams[key];
        }
        return acc;
    }, {});

    const params = new URLSearchParams(paramsNormalized);

    const response = await axiosInstance.get("/providers/transactions", {
        params
    });

    if (!response?.data) return {};

    return {
        ...response.data,
        items: response.data.items.map(mapDtoToTransaction)
    };
}

export async function getTransactionsSuggestions(searchParams) {
    const paramsNormalized = Object.keys(searchParams).reduce((acc, key) => {
        if (searchParams[key] !== null) {
            acc[key] = searchParams[key];
        }
        return acc;
    }, {});

    const params = new URLSearchParams(paramsNormalized);

    const response = await axiosInstance.get("/providers/transactions/suggestions", {
        params
    });

    if (!response?.data) return {};

    return {
        ...response.data,
        items: response.data.items.map(mapDtoToTransaction)
    };
}


export async function getTransaction(id) {
    const response = await axiosInstance.get("/providers/transactions/" + id);

    if (!response?.data) return null;

    return mapDtoToTransaction(response.data);
}

export async function updateTransaction(id, transaction) {
    const response = await axiosInstance.put("/providers/transactions/" + id, transaction);

    if (!response?.data) return null;

    return mapDtoToTransaction(response.data);
}

export async function associateTagToTransactions(tagId, transactionsIds) {
    const response = await axiosInstance.post("/providers/transactions/associate", {
        category_id: tagId,
        transactions_ids: transactionsIds,
        contact_id: null
    });

    if (!response?.data) return null;

    return mapDtoToCategory(response.data);
}

export async function associateContactToTransactions(contactId, transactionsIds) {
    const response = await axiosInstance.post("/providers/transactions/associate", {
        category_id: null,
        transactions_ids: transactionsIds,
        contact_id: contactId
    });

    if (!response?.data) return null;

    return mapDtoToCategory(response.data);
}

export async function dissociateTagToTransactions(tagId, transactionsIds) {
    const response = await axiosInstance.post("/providers/transactions/dissociate", {
        category_id: tagId,
        transactions_ids: transactionsIds,
        remove_contact: false
    });

    if (!response?.data) return null;

    return mapDtoToCategory(response.data);
}

export async function dissociateContactToTransactions(transactionsIds) {
    const response = await axiosInstance.post("/providers/transactions/dissociate", {
        category_id: null,
        transactions_ids: transactionsIds,
        remove_contact: true
    });

    if (!response?.data) return null;

    return mapDtoToCategory(response.data);
}


export async function exportTransactions(params) {
    const response = await axiosInstance.post("/providers/transactions/export", params);

    if (!response?.data) return null;

    return response.data;
}

export async function updateTransactionsDescription(transactionsIds, description) {
    const body = {
        transactions_ids: transactionsIds,
        operation: "modify_description",
        value: description
    }

    await axiosInstance.put("/providers/transactions", body);
}

export async function updateTransactionsComment(transactionsIds, comment) {
    const body = {
        transactions_ids: transactionsIds,
        operation: "modify_comment",
        value: comment
    }

    await axiosInstance.put("/providers/transactions", body);
}

export async function updateTransactionsIgnore(transactionsIds, ignore) {
    const body = {
        transactions_ids: transactionsIds,
        operation: "modify_exclude_accounting",
        value: ignore
    }

    await axiosInstance.put("/providers/transactions", body);
}

export async function deleteTransactionsQuery(transactionsIds) {
    const body = {
        transactions_ids: transactionsIds,
        operation: "delete",
        value: null
    }

    await axiosInstance.put("/providers/transactions", body);
}


export async function updateTransactionsCategories(transactionsIds, { added, removed }) {
    const body = {
        transactions_ids: transactionsIds,
        operation: "modify_categories",
        value: added, // ["added-category-1", "added-category-2"]
        value_secondary: removed // ["removed-category-3"]
    }

    await axiosInstance.put("/providers/transactions", body);
}

export async function getTransactionFiles(id) {
    const response = await axiosInstance.get(`/providers/transactions/${id}/files`);

    if (!response?.data) return null;

    return response.data.map(mapDtoToFile).sort(sortFileByCreationDate);
}

export async function getTransactionFileDownloadUrl(id, fileId) {
    const response = await axiosInstance.get(`/providers/transactions/${id}/files/${fileId}/download`);

    if (!response?.data) return null;

    return response.data;
}

export async function deleteTransactionFile(id, fileId) {
    await axiosInstance.delete(`/providers/transactions/${id}/files/${fileId}`);
}

export async function uploadTransactionFile(id, file) {
    const params = {
        name: file.name,
        size: file.size,
        type: file.type || "application/octet-stream",
    }

    const urlResponse = await axiosInstance.post(`/providers/transactions/${id}/files`, params);

    if (urlResponse.status === 401) {
        return null;
    }

    if (!urlResponse?.data) {
        throw new Error(`Error getting upload url for file ${file.name} with size ${file.size} and type ${file.type} for transaction ${id}`);
    }

    const { upload_url, id: fileId } = urlResponse.data;

    await fetch(upload_url, {
        method: "PUT",
        body: file,
        headers: {
            "Content-Type": file.type,
        }
    });

    await axiosInstance.post(`/providers/transactions/${id}/files/${fileId}/confirm`);
}

export async function uploadTransactionFiles(id, files) {
    const promises = Array.from(files).map((file) => uploadTransactionFile(id, file));
    await Promise.all(promises);
}

export async function getCategoriesSuggestions(searchParams) {
    const params = new URLSearchParams(searchParams);
    const response = await axiosInstance.get("/providers/categories/suggestions", { params });

    if (!response?.data) return {};

    return {
        ...response.data,
        items: response.data.items?.map(suggestion => ({
            ...suggestion,
            transaction: mapDtoToTransaction(suggestion.transaction)
        })) ?? []
    }
}

export async function applyCategorySuggestion({ id, action }) {
    await axiosInstance.put(`/providers/categories/suggestions/${id}`, {
        action
    });
}

export async function splitTransaction(transactionId, slices) {
    const response = await axiosInstance.post(`/providers/transactions/${transactionId}/split`, slices);

    if (!response?.data) return null;

    return response.data;
}

export async function deleteTransactionSlices(transactionId) {
    await axiosInstance.delete(`/providers/transactions/${transactionId}/split`);
}

export async function createTransaction(transaction) {
    const response = await axiosInstance.post("/providers/transactions", transaction);

    if (!response?.data) return null;

    return mapDtoToTransaction(response.data);
}


async function getCSVImport(fileId) {
    const STATUS = {
        PENDING: "PENDING",
        ERROR: "ERROR",
        OK: "OK"
    }

    const STATUS_ERROR_MAPPING = "import_requires_mapping"

    const response = await axiosInstance.get(`/providers/transactions/import/${fileId}`);

    if (!response?.data) {
        throw new Error(`Error getting import status for file: ${fileId}`);
    }

    if (response.data.status === STATUS.ERROR) {
        if (response.data.status_message.description === STATUS_ERROR_MAPPING) {
            return {
                requiresMapping: true,
                error: response.data.status_message.description,
                errorInfo: response.data.status_message.info,
                columns: response.data.status_message.info.columns
            }
        }

        return {
            requiresMapping: false,
            error: response.data.status_message.description,
            errorInfo: response.data.status_message.info,
            columns: null
        }
    }

    if (response.data.status === STATUS.PENDING) {
        await wait(500);
        return await getCSVImport(fileId);
    }

    return {
        requiresMapping: false,
        error: null,
        errorInfo: null,
        columns: null
    }
}

export async function importTransactions(productId, fileCSV) {
    const params = {
        name: fileCSV.name,
        size: fileCSV.size,
        type: "application/csv",
        product_id: productId
    }

    const urlResponse = await axiosInstance.post("/providers/transactions/import", params);

    if (!urlResponse?.data) {
        throw new Error(`Error getting upload url for file ${fileCSV.name} with size ${fileCSV.size} and type ${fileCSV.type}`);
    }

    const { upload_url, id: fileId } = urlResponse.data;

    await fetch(upload_url, {
        method: "PUT",
        body: fileCSV,
        headers: {
            "Content-Type": "application/csv"
        }
    });

    const importResponse = await getCSVImport(fileId);
    return {
        ...importResponse,
        importId: fileId
    };
}

export async function mapCSVImport(fileId, body) {
    const response = await axiosInstance.post(`/providers/transactions/import/${fileId}/map`, body);
    if (!response?.data) {
        throw new Error(`Error mapping file ${fileId}`);
    }

    const importResponse = await getCSVImport(fileId);

    return importResponse;
}
