import React from "react";
import PropTypes from "prop-types";
import Button from "Components/Button";
import { useErrors } from "Components/ErrorsProvider";
import { useQueryClient } from "@tanstack/react-query";
import { createWorkspaceToProject } from "Services/user";
import FormProvider, { RHFTextField } from "Components/HookForm";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from "react-i18next";

export default function ChangeWorkspaceCreateForm(props) {
    const { onError, projectId, onCreate } = props;
    const { showUnknownError } = useErrors();

    const queryClient = useQueryClient();
    const { t } = useTranslation("common");

    const workspaceSchema = yup.object().shape({
        name: yup.string().required(t("select-workspace.name-is-required"))
            .min(3, t("select-workspace.name-must-be-at-least-3-characters"))
            .max(50, t("select-workspace.name-must-have-a-maximum-of-50-characters"))
    });

    const methods = useForm({
        resolver: yupResolver(workspaceSchema),
        defaultValues: {
            name: "",
        },
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = async (data) => {
        try {
            const response = await createWorkspaceToProject(projectId, data);

            await Promise.all([
                queryClient.invalidateQueries(["projects"]),
                queryClient.invalidateQueries(["project-selected"]),
            ]);

            onCreate(response);
        } catch (error) {
            onError?.();
            showUnknownError(error);
        }
    }

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <div className="py-4">
                <RHFTextField
                    label={t("select-workspace.name")}
                    name="name"
                />
            </div>

            <div className="d-flex justify-content-center">
                <Button primary size="large" type="submit" isBusy={isSubmitting}>
                    {t("select-workspace.create")}
                </Button>
            </div>
        </FormProvider>
    )
}

ChangeWorkspaceCreateForm.propTypes = {
    projectId: PropTypes.string,
    onCreate: PropTypes.func.isRequired,
    onError: PropTypes.func
}