
import PropTypes from "prop-types";
import React from "react";

const CircleInfoSmall = ({ color, size, ...props }) => (
    <svg viewBox="0 0 20 20" width={size} height={size} {...props}>
        
	<path d="M18 10a8 8 0 1 1-16.001 0A8 8 0 0 1 18 10Zm-7-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM9 9a1 1 0 0 0 0 2v3a1 1 0 0 0 1 1h1a1 1 0 0 0 0-2v-3a1 1 0 0 0-1-1H9Z" fill={color} fillRule="evenodd"/>

    </svg>
);

export default CircleInfoSmall;

CircleInfoSmall.propTypes = {
    color: PropTypes.string.isRequired,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
