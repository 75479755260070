import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Dialog from "Components/Dialog";
import classes from "./CategorizerModal.module.css";
import Button from "Components/Button";
import CategorizerSlice from "./CategorizerSlice";
import { AnimatePresence } from "framer-motion";
import clsx from "clsx";
import useCategories from "Hooks/useCategories";
import Loading from "Components/Loading";
import useCategoriesSuggestions from "Hooks/useCategoriesSuggestions";
import Icon from "Components/Icon";
import IconButton from "Components/IconButton";
import { useTranslation } from "react-i18next";
import useMediaQuery from "Hooks/useMediaQuery";

export default function CategorizerModal(props) {
    const { open, onClose } = props;
    const { isLoading, categories } = useCategories();
    const {
        applysuggestion, totalSuggestions, next, prev, categorieSuggestion, nextCategorySuggestion,
        isFetching, currentIndex, hasFinished, initCategorization, refetch
    } = useCategoriesSuggestions();

    const isDesktop = useMediaQuery('(min-width: 768px)');
    const { t } = useTranslation("common");

    useEffect(() => {
        if (open) {
            initCategorization();

            return () => {
                refetch();
            }
        }
    }, [open]);

    const handleCategorize = (action) => {
        applysuggestion({ id: categorieSuggestion.id, action, categoryId: categorieSuggestion.transaction_category_id, transactionId: categorieSuggestion.transaction.id })
    }

    const showLoading = isLoading || isFetching;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            className={classes.dialog}
            title={(
                <div className="d-flex align-items-center">
                    <IconButton className={classes.paginationButton} onClick={prev} disabled={currentIndex === 1}><Icon name="arrow-left-small" size={24} color="currentColor" /></IconButton>
                    <span className={classes.paginationText}>
                        {`${currentIndex} ${t("of")} ${totalSuggestions}`}
                    </span>
                    <IconButton className={classes.paginationButton} onClick={next} disabled={currentIndex >= totalSuggestions}><Icon name="arrow-right-small" size={24} color="currentColor" /></IconButton>
                </div>
            )}
            keepSupportChat={isDesktop}
        >
            {showLoading && (
                <div className={classes.content}>
                    <div className={classes.isLoading}>
                        <Loading />
                    </div>
                </div>
            )}

            {!showLoading && hasFinished && (
                <div className={classes.content}>
                    <div className="py-4 mb-4 d-flex flex-column align-items-center">
                        <h4 className="mb-2">{t("congratulations")}</h4>
                        <p className="text-muted mb-4">
                            {t("categorizer.finished")}
                        </p>
                        <Button primary size="large" onClick={onClose}>
                            {t("accept")}
                        </Button>
                    </div>
                </div>
            )}

            {!showLoading && !hasFinished && (
                <div className={classes.content}>
                    <div className={clsx(classes.relative, classes.group)}>
                        <AnimatePresence>
                            {nextCategorySuggestion && (
                                <CategorizerSlice
                                    categories={categories}
                                    key={`next-${nextCategorySuggestion.id}`}
                                    disabled
                                    className={classes.prevSlice}
                                    onCategorize={handleCategorize}
                                    {...nextCategorySuggestion}
                                />
                            )}

                            {categorieSuggestion && (
                                <CategorizerSlice
                                    key={`current-${categorieSuggestion.id}`}
                                    categories={categories}
                                    onCategorize={handleCategorize}
                                    {...categorieSuggestion}
                                />
                            )}
                        </AnimatePresence>
                    </div>
                </div>
            )}
        </Dialog>
    )
}

CategorizerModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}