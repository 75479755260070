import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { formatDateISO } from "Utils/formatters";
import isEqual from "lodash/isEqual";
import { All_TIME_PERIOD, CUSTOM_PERIOD, SEARCH_FILTERS, ROUTES } from "Utils/constants";

const SearchContext = createContext(null);
export const DEFAULT_FILTERS = {
    productsIds: null,
    amountFrom: null,
    amountTo: null,
    categories_ids: [],
    taxonomies_untagged_ids: [],
    invoicesMode: SEARCH_FILTERS.INVOICES_MODE.ALL,
    filesMode: SEARCH_FILTERS.FILES_MODE.ALL,
    ignoredMode: SEARCH_FILTERS.IGNORED_MODE.ALL,
    reversedSignMode: SEARCH_FILTERS.REVERSED_SIGN_MODE.ALL,
    splitMode: SEARCH_FILTERS.SPLIT_MODE.ALL,
    // ignore filters applied
    period: All_TIME_PERIOD,
    expense: true,
    income: true,
    dateFrom: null,
    dateTo: null,
    only_uncategorized: false,
    contactsIds: null
}

const REFRESH_STADES = {
    none: "none",
    refresh: "refresh",
    refrestAndNavigate: "refrestAndNavigate",
}

const toSnakeCase = (str) => str.replace(/([A-Z])/g, (g) => `_${g[0].toLowerCase()}`);
const normalizeArrayToExport = (arr) => {
    if (!Array.isArray(arr)) return null;

    if (arr.length === 0) return null;

    return arr
}

const mapStoreToExportParams = (store) => {
    const types = [store.expense && "expense", store.income && "income"].filter(Boolean);

    return {
        amount_from: store.amountFrom,
        amount_to: store.amountTo,
        type: types.length === 1 ? types[0] : null,
        products_ids: store.productsIds ?? null,
        contacts_ids: store.contactsIds ?? null,
        categories_ids: store.only_uncategorized ? null : normalizeArrayToExport(store.categories_ids),
        query: store.query,
        invoices_mode: store.invoicesMode,
        files_mode: store.filesMode,
        ignored_mode: store.ignoredMode,
        reversed_sign_mode: store.reversedSignMode,
        split_mode: store.splitMode,
        order_by: store.orderBy,
        order: store.order,
        taxonomies_untagged_ids: normalizeArrayToExport(store.taxonomies_untagged_ids),
        only_uncategorized: store.only_uncategorized,
        ...(store.period === CUSTOM_PERIOD && {
            date_from: formatDateISO(store.dateFrom),
            date_to: formatDateISO(store.dateTo),
            period: null,
        }),
        ...(store.period !== CUSTOM_PERIOD && {
            date_from: null,
            date_to: null,
            period: store.period,
        }),
    }
}

const mapStoreToSearchParams = (store) => {
    const types = [store.expense && "expense", store.income && "income"].filter(Boolean);

    return {
        ...(store.query && { q: encodeURIComponent(store.query) }),
        ...(types.length === 1 && { type: types[0] }),
        ...(Array.isArray(store.categories_ids) && store.categories_ids.length > 0 && { categories_ids: store.only_uncategorized ? null : store.categories_ids }),
        ...(store.productsIds && { products_ids: store.productsIds }),
        ...(store.period === CUSTOM_PERIOD && { date_from: formatDateISO(store.dateFrom), date_to: formatDateISO(store.dateTo) }),
        ...(store.invoicesMode !== SEARCH_FILTERS.INVOICES_MODE.ALL && { invoices_mode: store.invoicesMode }),
        ...(store.filesMode !== SEARCH_FILTERS.FILES_MODE.ALL && { files_mode: store.filesMode }),
        ...(store.ignoredMode !== SEARCH_FILTERS.IGNORED_MODE.ALL && { ignored_mode: store.ignoredMode }),
        ...(store.reversedSignMode !== SEARCH_FILTERS.REVERSED_SIGN_MODE.ALL && { reversed_sign_mode: store.reversedSignMode }),
        ...(store.splitMode !== SEARCH_FILTERS.SPLIT_MODE.ALL && { split_mode: store.splitMode }),
        ...(store.taxonomies_untagged_ids.length > 0 && { taxonomies_untagged_ids: store.taxonomies_untagged_ids }),
        ...(store.contactsIds && { contacts_ids: store.contactsIds }),
        amount_from: store.amountFrom,
        amount_to: store.amountTo,
        order_by: store.orderBy,
        order: store.order,
        period: store.period,
        only_uncategorized: store.only_uncategorized,
    }
}

const SearchProvider = ({ children }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [orderBy, setOrderBy] = useState("date_booked");
    const [order, setOrder] = useState("desc");

    const query = searchParams.get("q") ?? "";
    const expense = searchParams.get("expense")
        ? searchParams.get("expense") !== "false"
        : DEFAULT_FILTERS.expense;

    const income = searchParams.get("income")
        ? searchParams.get("income") !== "false"
        : DEFAULT_FILTERS.income;

    const dateFrom = searchParams.get("date_from")
        ? new Date(searchParams.get("date_from"))
        : DEFAULT_FILTERS.dateFrom;

    const dateTo = searchParams.get("date_to")
        ? new Date(searchParams.get("date_to"))
        : DEFAULT_FILTERS.dateTo;

    const period = searchParams.get("period") ?? DEFAULT_FILTERS.period;
    const amountFrom = searchParams.get("amount_from") ? parseInt(searchParams.get("amount_from")) : DEFAULT_FILTERS.amountFrom;
    const amountTo = searchParams.get("amount_to") ? parseInt(searchParams.get("amount_to")) : DEFAULT_FILTERS.amountTo;

    const categories_ids = searchParams.get("categories_ids")?.split(",") ?? DEFAULT_FILTERS.categories_ids;
    const productsIds = searchParams.get("products_ids")?.split(",") ?? DEFAULT_FILTERS.productsIds;
    const contactsIds = searchParams.get("contacts_ids")?.split(",") ?? DEFAULT_FILTERS.contactsIds;

    const invoicesMode = searchParams.get("invoices_mode") ?? DEFAULT_FILTERS.invoicesMode;
    const filesMode = searchParams.get("files_mode") ?? DEFAULT_FILTERS.filesMode;
    const ignoredMode = searchParams.get("ignored_mode") ?? DEFAULT_FILTERS.ignoredMode;
    const reversedSignMode = searchParams.get("reversed_sign_mode") ?? DEFAULT_FILTERS.reversedSignMode;
    const splitMode = searchParams.get("split_mode") ?? DEFAULT_FILTERS.splitMode;

    const only_uncategorized = searchParams.get("only_uncategorized") ? searchParams.get("only_uncategorized") === "true" : DEFAULT_FILTERS.only_uncategorized;
    const taxonomies_untagged_ids = searchParams.get("taxonomies_untagged_ids")?.split(",") ?? DEFAULT_FILTERS.taxonomies_untagged_ids;

    const [search, setSearch] = useState(query);
    const [refresh, setRefresh] = useState(REFRESH_STADES.none);

    const [filters, _setFilters] = useState({
        expense,
        income,
        productsIds,
        dateFrom,
        dateTo,
        amountFrom,
        amountTo,
        categories_ids,
        invoicesMode,
        filesMode,
        ignoredMode,
        reversedSignMode,
        splitMode,
        period,
        only_uncategorized,
        taxonomies_untagged_ids,
        contactsIds
    });

    const [store, setStore] = useState({
        query,
        expense,
        income,
        productsIds,
        dateFrom,
        dateTo,
        amountFrom,
        amountTo,
        categories_ids,
        period,
        invoicesMode,
        filesMode,
        ignoredMode,
        reversedSignMode,
        splitMode,
        only_uncategorized,
        taxonomies_untagged_ids,
        contactsIds
    });

    const searchParamsNormalized = useMemo(() => mapStoreToSearchParams({ ...store, orderBy, order }), [store, orderBy, order]);
    const exportParams = useMemo(() => mapStoreToExportParams({ ...store, orderBy, order }), [store, orderBy, order]);

    const setFilters = (newFilters, options = {}) => {
        _setFilters(filters => ({
            ...filters,
            ...newFilters,
        }));

        if (options.autoRefresh) setRefresh(REFRESH_STADES.refresh);
        if (options.forceNavigate) setRefresh(REFRESH_STADES.refrestAndNavigate);
    }

    const refreshResults = ({ forceNavigate = false } = {}) => {
        const filtersNormalized = Object.keys(filters).reduce((acc, key) => {
            const value = filters[key] === "" ? DEFAULT_FILTERS[key] : filters[key];
            return {
                ...acc,
                [key]: value
            }
        }, {});

        if (!isEqual(filtersNormalized, filters)) {
            setFilters(filtersNormalized);
        }

        Object.keys(filtersNormalized).forEach(key => {
            if (isEqual(filtersNormalized[key], DEFAULT_FILTERS[key])) {
                searchParams.delete(toSnakeCase(key));
            } else {
                if (filtersNormalized[key] instanceof Date) {
                    searchParams.set(toSnakeCase(key), formatDateISO(filtersNormalized[key]));
                } else {
                    searchParams.set(toSnakeCase(key), filtersNormalized[key]);
                }
            }
        });

        if (search === "") {
            searchParams.delete("q");
        } else {
            searchParams.set("q", search);
        }

        const newSearchParams = { query: search, ...filtersNormalized }

        if (!isEqual(newSearchParams, store)) {
            setStore(newSearchParams);
            if (!forceNavigate) {
                setSearchParams(searchParams);
            }
        }

        if (forceNavigate) {
            navigate({
                pathname: ROUTES.search,
                search: searchParams.toString(),
            })
        }
    }

    useEffect(() => {
        if (refresh === REFRESH_STADES.none) return;

        if (refresh === REFRESH_STADES.refresh) {
            refreshResults();
        }

        if (refresh === REFRESH_STADES.refrestAndNavigate) {
            refreshResults({ forceNavigate: true });
        }

        setRefresh(REFRESH_STADES.none);

    }, [refresh])

    const undoFilters = () => {
        // eslint-disable-next-line no-unused-vars
        const { query, ...oldFilters } = store;
        setFilters(oldFilters);
    }

    const clearFilters = () => {
        setFilters(DEFAULT_FILTERS);
        setSearch("");
        setRefresh(REFRESH_STADES.refresh);
    }

    useEffect(() => {
        return () => {
            if (pathname === ROUTES.search) {
                clearFilters();
            }
        }
    }, [pathname])

    const filtersAppliedCount = useMemo(() => {
        const filtersKeys = [
            "productsIds",
            "amountFrom",
            "amountTo",
            "invoicesMode",
            "filesMode",
            "ignoredMode",
            "reversedSignMode",
            "splitMode",
        ];

        const filtersTaxonomies = [
            "taxonomies_untagged_ids",
            "categories_ids",
        ]

        const hasFiltersTaxonomies = filtersTaxonomies.some(key => !isEqual(filters[key], DEFAULT_FILTERS[key]));
        const initialCount = hasFiltersTaxonomies ? 1 : 0;

        return filtersKeys.reduce((acc, key) => {
            if (!isEqual(filters[key], DEFAULT_FILTERS[key])) {
                return acc + 1;
            }

            return acc;
        }, initialCount);
    }, [filters])

    const contextValue = {
        orderBy,
        setOrderBy,
        order,
        setOrder,
        search,
        setSearch,
        filters,
        setFilters,
        undoFilters,
        refreshResults,
        exportParams,
        searchParams: searchParamsNormalized,
        clearFilters,
        filtersAppliedCount,
    }

    return <SearchContext.Provider value={contextValue}>{children}</SearchContext.Provider>;
}

export default SearchProvider;

SearchProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export const useSearch = () => {
    const context = useContext(SearchContext);

    if (!context) {
        throw new Error("useSearch must be used within a SearchProvider");
    }

    return context;
}