import { Check, Indeterminate } from "./CheckboxIcons";
import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import classes from "./Checkbox.module.css";
import cx from "classnames";

const Checkbox = forwardRef((props, ref) => {
  const { indeterminate, className, checked, children, fullWidth, ...htmlProps } = props;
  const checkboxRef = React.useRef(null);

  useImperativeHandle(ref, () => checkboxRef?.current);

  useEffect(() => {
    if (indeterminate) {
      checkboxRef.current.indeterminate = true;
      checkboxRef.current.checked = false;
    } else if (checked) {
      checkboxRef.current.indeterminate = false;
      checkboxRef.current.checked = true;
    } else {
      checkboxRef.current.indeterminate = false;
      checkboxRef.current.checked = false;
    }
  }, [indeterminate, checked]);

  const handleClick = (ev) => ev.stopPropagation();


  return (
    <label onClick={handleClick} className={cx(classes.base, fullWidth && classes.fullWidth, className)}>
      <input className={classes.input} type="checkbox" ref={checkboxRef} {...htmlProps} />

      <span aria-hidden="true" className={classes.boxIcon}>
        {indeterminate
          ? <Indeterminate />
          : checked && <Check />
        }
      </span>

      {children && <span className={classes.content}>{children}</span>}
    </label>
  )
});

export default Checkbox;

Checkbox.displayName = "Checkbox";

Checkbox.propTypes = {
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
};