import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Dropdown, { DropdownItem, DropdownToggle } from 'Components/Dropdown';
import classes from './SelectCustom.module.css';
import clsx from 'clsx';
import TextField from 'Components/TextField';
import { useTranslation } from 'react-i18next';
import { normalize } from 'Utils/strings';

const filterAndSort = (options, search) => {
    const filtered = options.filter(option => {
        const normalizedSearch = normalize(search);
        const normalizedLabel = normalize(option.label);

        return normalizedLabel.includes(normalizedSearch);
    });

    const sorted = filtered.sort((a, b) => {
        const normalizedSearch = normalize(search);
        const normalizedLabelA = normalize(a.label);
        const normalizedLabelB = normalize(b.label);

        const distanceA = normalizedLabelA.indexOf(normalizedSearch);
        const distanceB = normalizedLabelB.indexOf(normalizedSearch);

        return distanceA - distanceB;
    })

    return sorted;
}

export default function SelectCustom(props) {
    const { onChange, options, value, className, enableFilter = false } = props;
    const [query, setQuery] = useState("");
    const dropdownRef = useRef(null);
    const title = options.find(option => option.value === value)?.label ?? "";

    const { t } = useTranslation("common");

    const handleFilter = (ev) => {
        setQuery(ev.target.value);
    }

    const handlePressEnter = (ev) => {
        if (ev.key === "Enter" && query.trim() !== "") {
            const [option] = filterAndSort(options, query);
            if (option) {
                onChange(option.value);
                setQuery("");
                dropdownRef.current.close();
            }
        }
    }

    const optionsFiltered = query.trim() ? filterAndSort(options, query) : options;

    return (
        <>
            <Dropdown
                ref={dropdownRef}
                toggle={
                    <DropdownToggle className={className}>
                        <div className={classes.truncate}><span>{title}</span></div>
                    </DropdownToggle>
                }
            >
                {enableFilter && (
                    <div className={classes.search}>
                        <TextField
                            type="search"
                            placeholder={t("filter-placeholder")}
                            value={query}
                            onChange={handleFilter}
                            className={classes.searchInput}
                            onKeyPress={handlePressEnter}
                        />
                    </div>
                )}
                <div className={classes.options}>
                    {optionsFiltered.length === 0 && (
                        <div className="text-muted text-small p-2">
                            {t("no-results-found")}
                        </div>
                    )}

                    {optionsFiltered.map(option => (
                        <DropdownItem
                            className={clsx(option.value === value && classes.actived)}
                            key={option.value}
                            onClick={() => {
                                onChange(option.value);
                                setQuery("");
                            }}
                        >
                            {option.label}
                        </DropdownItem>
                    ))}
                </div>
            </Dropdown>
        </>
    );
}

SelectCustom.propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
    })).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    enableFilter: PropTypes.bool,
};