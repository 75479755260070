import React, { forwardRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useInfiniteContacts from 'Hooks/useInfiniteContacts';
import AutocompleteInfinite from './AutocompleteInfinite';

const AutocompleteContacts = forwardRef(function AutocompleteContacts(props, ref) {
  const { label, onChange, value, error, disabled, onAdd } = props;

  const [query, setQuery] = useState("");
  const { data, isFetching, fetchNextPage, hasNextPage } = useInfiniteContacts({ query });

  const options = useMemo(() => {
    return data?.pages?.reduce((acc, page) => {
      return [...acc, ...page.items]
    }, []) ?? [];
  }, [data]);

  return (
    <AutocompleteInfinite
      ref={ref}
      disabled={disabled}
      label={label}
      options={options}
      error={error}

      value={value}
      onSelect={onChange}
      onAdd={onAdd}

      query={query}
      onChangeQuery={setQuery}

      isFetching={isFetching}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
    />
  )
});

AutocompleteContacts.propTypes = {
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
}

export default AutocompleteContacts;